.contact-background {
    background-color: #efefef;
    position: relative;
    height: 55vh;
    text-align: center;
}

.container-contact {
    display: flex;
    flex-direction: column;
    background-color: #efefef;
    height: 30vh;
    justify-content: center;
    padding: 1%;
    align-items: center;
}

.grid-column-contact {
    margin: 10px;
    justify-content: center;
    text-align: center;
}

.contact-title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;

}

.hr-contact {
    width: 50px;
    height: 2px;
    text-align: center;
    margin: auto;
    align-items: center;
    background-color: red;
    border: 0;
    opacity: 1.0;
}

.contact-text {
    font-family: Merriweather;
    width: 40vw;
    text-align: center;
    letter-spacing: 2px;
}

.faphone {
    font-size: xx-large;
}

.grid-row-contact {
    display: flex;
    flex-direction: row;
    width: 10%;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    color:red;
    background-color: #efefef;
}

.container-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    margin: 2%;
    align-items: center;
    background-color: #efefef;
}

.grid-row-contact:hover{
    font-size: 150px;
}

@media screen and (max-width: 1920px) {}

@media screen and (max-width: 900px) {}

@media screen and (max-width: 500px) {
    .contact-background {
        height: 70vh;
    }

    .container-contact {
        height: 45vh;
    }

    .grid-column-contact {
        margin: 3%;
    }
    .contact-text {
        font-family: Merriweather;
        width: 65vw;
        text-align: center;
        font-size: small;
    }

    .grid-row-contact {
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: center;
        align-items: center;
        font-size: 100px;
        color:red;
        background-color: #efefef;
    }
    
}