.container {
    display:flex;
    flex-direction: column;
    background-color: red;
    height: 30vh;
    justify-content: center;
    padding: 1%;
    align-items: center;
}

.grid-column {
    width: 75%;
    margin: 10px;
    justify-content: center;
    text-align: center;
}

.text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: xl-large;
    color: white;
    font-weight: lighter;
    letter-spacing: 2px;
}

.hr-menu {
    width: 50px;
    text-align: center;
    margin: auto;
    align-items: center;
    color: white;

}

.text-sub {
    font-family: Merriweather;
    font-size: large;
    color: rgb(250, 245, 246);
    letter-spacing: 2px;
}

.menu-btn {
    border-radius: 50px;
    background-color: white;
    color: red;
    margin: auto;
}

.menu-btn:hover {
    color: red;
    background-color: rgb(231, 228, 224);
}

@media screen and (max-width: 1920px) {}

@media screen and (max-width: 900px) {}

@media screen and (max-width: 500px) {
    .container {
        height: 40vh;
    }

    .text {
        font-size: xx-large;
    }

    .text-sub {
        font-size: small;
    }
}