#container {
    display:flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    padding: 1%;
    align-items: center;

}

#grid-column {
    width: 75%;
    margin: 10px;
    justify-content: center;
    text-align: center;

}

#hr-about {
    width: 50px;
    height: 2px;
    text-align: center;
    margin: auto;
    align-items: center;
    background-color: red;
    border: 0;
    opacity: 1.0;
}

#aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    align-items: center;
}

.about-title {

    background-color: white;
    position: relative;
    height: 5vh;
    text-align: center;
    letter-spacing: 2px;
}

.about-title-text {
    font-family:  Arial, Helvetica, sans-serif;
    font-weight: lighter;
}

.image-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: first baseline;     /* Center vertically */
    height: 40vh;           /* Set a fixed height for the container */
    width: 100%;      
}

.crew-img {
    max-width: 50vw;
    max-height: 50vh;

}

@media screen and (max-width: 1920px) {}

@media screen and (max-width: 900px) {
    #container {
        padding-bottom: 5%;
    }

    

    .crew-img {
        max-width: 71vw;
        max-height: 71vh;
    }
}

@media screen and (max-width: 500px) {

    .crew-img {
        max-width: 70vw;
        max-height: 70vh;
    }
}