.background {
    flex: 1;
    overflow: hidden;
    margin-top: -75px;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-attachment: fixed;
    max-height: 100vh;

}

.small-background {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 340px;
    /* Adjust as needed */
}

.small-background-smallscreen {
    display: none;
}

.hr-header {
    position: absolute;
    margin-top: 55vh;
    z-index: 2;
    border: 1px solid red;
    width: 50px;
    z-index: 4;
    opacity: 1.0
}

.hr-text {
    position: absolute;
    font-family: Merriweather;
    color: white;
    margin-top: 60vh;
    font-size: large;
    text-align: center;
    letter-spacing: 2px;
}

.location {
    font-size: x-large;
    color:white;
    font-family: var(--font-alt);
    letter-spacing: 2px;
    font-weight: 600;
}

button {
    background-color: red;
    padding: 15px 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-alt);
    color: var(--color-eggshell);
    font-size: large;
    font-weight: bold;
    border: none;
    z-index: 2;
}

button::after {
    display: block;
    padding-bottom: 100%;
    color: var(--color-black);
}

button:hover {
    cursor: pointer;
    color: var(--color-white);
    font-weight: bold;
    background-color: rgb(206, 2, 2);
}

.menu-route-button {
    position: absolute;
    margin-top: 70vh;
    z-index: 2;
}

.btn-menu {
    border-radius: 50px;
}



@media screen and (max-width: 1920px) {
    .background {
        margin-top: -61px;
    }

    .small-background {
        top: 38%;
    }

    .hr-header {
        margin-top: 70vh;
    }

    .hr-text {
        margin-top: 75vh;
        padding-left: 5%;
        padding-right: 5%;
        text-align: center;
    }


    .menu-route-button {
        margin-top: 85vh;

    }

}

@media screen and (max-width: 900px) {
    .background {
        max-height: 100vh;
        margin-top: -61px;
    }

    .small-background {
        display: none;
    }

    .small-background-smallscreen {
        position: absolute;
        top: 45%;
        left: 43%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 150px;
        /* Adjust as needed */
        display: block;
    }

    .hr-header {
        margin-top: 70vh;
    }

    .hr-text {
        margin-top: 73vh;

    }

    .menu-route-button {
        margin-top: 80vh;
    }

}

@media screen and (max-width: 500px) {
    .background {
        margin-top: -50px;
    }

    .hr-header {
        margin-top: 65vh;
    }

    .hr-text {
        margin-top: 70vh;
        font-size: small;
        letter-spacing: 2px;
    }

    .small-background-smallscreen {
        position: absolute;
        top: 38%;
        left: 38vw;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 150px;
        /* Adjust as needed */
        display: block;
    }

    @media screen and (max-width: 400px) {
        .small-background-smallscreen {
            left: 37vw;
        }

    }

}