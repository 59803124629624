.sticky__nav {
    position: sticky;
    top: 0;
    z-index: 100;
}

.app__navbar {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    padding: 0.75rem 1rem;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    transition:  .3s ease-in;
}

.app__navbar_bg {
    background-color: var(--color-white);
}

.app__navbar-logo {
    padding-left: 1rem;
    align-items: center;
    font-size: large;
    font-family: var(--font-alt);
    color: var(--color-eggshell);
    cursor: pointer;
}

.app__navbar-logo:hover {
    color: var(--color-red);

}

.app__navbar-logo-bg {
    color: var(--color-black);
}

.app__navbar-logo-bg:hover {
    color: var(--color-red);
}

.app__navbar-links {
    flex: 0;
    display: flex;
    list-style: none;
    justify-content: flex-end;
}


.app__navbar-links li {
    font-size: large;
    font-family: var(--font-alt);
    margin: 0 1rem;
    cursor: pointer;
    font-weight: 1000;
}

.app__navbar-logo-smallscreen {
    display: none;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 43px;
    left: 0;
    width: 100%;
    background: var(--color-white);
    transition: 1s ease;
    animation: fade-in 0.25s;
    flex-direction: column;
    z-index: 5;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

.app__navbar-smallscreen_links {
    list-style: none;
    justify-content: flex-start;
    padding-top: .2rem;
}

.app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    color: var(--color-black);
    font-size: 1rem;
    text-align: center;
    font-family: var(--font-alt);
}

.app__navbar-smallscreen_links li:hover {
    margin: 1rem;
    cursor: pointer;
    color: var(--color-red);
    font-size: 1rem;
    text-align: center;
    font-family: var(--font-alt);
}

.p__opensans-bg:hover {
    color: var(--color-red);
}

.p__opensans-bg {
    font-family: var(--font-alt);
}

@media screen and (max-width: 650px) {
    .app__navbar {
        background-color: var(--color-white);
    }

    .app__navbar-links{
        display: none;
    }

    .app__navbar-logo {
        padding-left: none;
        align-items: center;
        font-size: small;
        font-family: var(--font-alt);
        color: var(--color-black);
    }
    .app__navbar-logo:hover {
        color: var(--color-red);
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 235px) {
    .app__navbar-logo {
        padding-left: none;
        align-items: center;
        font-size: xx-small;
        font-family: var(--font-alt);
        color: var(--color-black);
    }

    .app__navbar-logo:hover {
        color: var(--color-red);
    }
}